<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="sort" label="排序" show-overflow-tooltip></el-table-column>
        <el-table-column prop="pic" label="图片" show-overflow-tooltip>
          <template v-slot="s">
            <img :src="s.row.pic | tomedia" style="width: 3rem;height: 3rem;border-radius: .5rem">
          </template>
        </el-table-column>
        <el-table-column label="响应" show-overflow-tooltip>
          <template #default="s">
            <template v-if="s.row.gird_mini">
              <div class="y-desc">跳转小程序</div>
              <div>
                <div>AppID:{{s.row.gird_app_id}}</div>
                <div>路径:{{s.row.link}}</div>
                <div>二维码:{{s.row.gird_qrcode ? '已' : '未'}}配置</div>
              </div>
            </template>
            <template v-else>
              <div class="y-desc">跳转连接</div>
              <div>{{s.row.link ? s.row.link : '未配置'}}</div>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="text" label="文本" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot="header">
            <el-button @click="editBox = true">添加导航</el-button>
          </template>
          <template v-slot="s">
            <el-button @click="edit(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="编辑导航" :visible.sync="editBox" @close="editClose">
      <el-form label-width="6rem" style="padding: 0 2rem">
        <el-form-item label="排序">
          <el-input-number v-model="editForm.sort"></el-input-number>
        </el-form-item>
        <el-form-item label="图片">
          <y_upload_img v-model="editForm.pic"></y_upload_img>
        </el-form-item>
        <el-form-item label="文本">
          <el-input v-model="editForm.text"></el-input>
        </el-form-item>
        <el-form-item label="跳转小程序">
          <el-switch v-model="editForm.gird_mini"></el-switch>
        </el-form-item>
        <template v-if="editForm.gird_mini">
          <el-form-item label="路径">
            <el-input v-model="editForm.link"></el-input>
          </el-form-item>
          <el-form-item label="AppID">
            <el-input v-model="editForm.gird_app_id"></el-input>
          </el-form-item>
          <el-form-item label="二维码">
            <y_upload_img v-model="editForm.gird_qrcode"></y_upload_img>
          </el-form-item>
        </template>
        <el-form-item label="链接" v-else>
          <y_choose_link v-model="editForm.link"></y_choose_link>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_upload_img from '@/components/y-form/y-upload/y_upload_img'
import y_choose_link from "@/components/shop/y_choose_link";
import {FastPageTargetNavState} from "@/view/card/plugin/home-page/fast-page/state";

const editFormTpl = {
  sort: 0,
  link: "",
  pic: "",
  target: FastPageTargetNavState.gird.value,
  text: "",

  gird_app_id:"",
  gird_qrcode:"",
  gird_mini:false,
}

export default {
  name: "gird",
  components: {
    y_choose_link,
    y_upload_img,
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {...editFormTpl},
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del({id}) {
      this.$u.api.card.plugin.homePage.fastPage.targetNavDel({id}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    edit(item) {
      this.editForm = {...item};
      this.editBox = true;
    },
    editClose() {
      this.editForm = {...editFormTpl}
    },
    load() {
      this.$u.api.card.plugin.homePage.fastPage.gridAll().then(res => {
        this.list = res;
      })
    },
    editSubmit() {
      this.$u.api.card.plugin.homePage.fastPage.targetNavEdit(this.editForm).then(() => {
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>